// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth, storage, functions } from './firebase';
import Login from './components/Login';
import UserProfile from './components/UserProfile';
import NotePlayerPublicLink from './components/NotePlayerPublicLink';
import Header from './components/Header';
import Notes from './components/Notes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import FloatingRecordButton from './components/FloatingRecordButton';
import RecordingPanel from './components/RecordingPanel';
import { v4 as uuidv4 } from 'uuid';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { initializeAudioRecorder } from './utils';
import { identifyUser, trackLogin, trackSignUp, trackFirebaseNoteCreated } from './analytics';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { userService } from './services/UserService';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);

  useEffect(() => {
    console.log("App.js useEffect");
    const initApp = async () => {
      await initializeAudioRecorder();
      
      const handleEmailSignIn = async () => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            email = window.prompt('Por favor, ingresa tu correo electrónico para confirmar el inicio de sesión:');
          }
          try {
            const result = await signInWithEmailLink(auth, email, window.location.href);
            window.localStorage.removeItem('emailForSignIn');
            setUser(result.user);
            identifyUser(result.user.uid, getUserProperties(result.user));
            trackLogin('email', result.user.uid);
            toast.success('Inicio de sesión exitoso!');
            navigate('/profile');
          } catch (error) {
            console.error('Error al completar el inicio de sesión con enlace:', error);
            toast.error('Error al completar el inicio de sesión. Por favor, intenta nuevamente.');
          }
        }
      };

      handleEmailSignIn();

      const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
          try {
            const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
            const userData = userDoc.exists() ? userDoc.data() : null;
            
            // Actualizar el servicio de usuario global
            userService.setUser(currentUser, userData);

            // Actualizar el estado local
            setUser(currentUser);
            
            const hasVisitedBefore = localStorage.getItem(`hasVisited_${currentUser.uid}`);
            setIsFirstTimeUser(!hasVisitedBefore);
            
            if (!user) {  // Solo si no hay un usuario previo
              identifyUser(currentUser.uid, getUserProperties(currentUser));
              
              if (!hasVisitedBefore) {
                localStorage.setItem(`hasVisited_${currentUser.uid}`, 'true');
                trackSignUp(currentUser.providerData[0]?.providerId || 'unknown', currentUser.uid);
              } else if (!isSignInWithEmailLink(auth, window.location.href)) {
                // Solo trackear login si no es un inicio de sesión por email link (ya trackeado arriba)
                trackLogin(currentUser.providerData[0]?.providerId || 'unknown', currentUser.uid);
              }
            }
          } catch (error) {
            console.error('Error al obtener datos de usuario:', error);
          }
        } else {
          userService.clearUser();
          setUser(null);
        }
        setLoading(false);
      });
      return () => unsubscribe();
    };

    initApp();
  }, [navigate, location, user]);

  // Function to get user properties for Mixpanel
  const getUserProperties = (user) => {
    return {
      "$name": user.displayName || "unknown",
      "$email": user.email || "unknown",
      "userLocale": navigator.language || "unknown",
      "appInterfaceLanguageLocale": navigator.language || "unknown",
      "isAdmin": false, // You might want to set this based on your app's logic
      "sendEmailAddress": user.email || "unknown",
      "signInProvider": user.providerData[0]?.providerId || "unknown",
      "userPhotoSet": !!user.photoURL,
      "countKeywords": 0, // You might want to set this based on your app's data
      "hasAboutMe": false // You might want to set this based on your app's data
    };
  };

  const handleOpenRecording = () => {
    setIsRecording(true);
  };

  const handleCloseRecording = (wasCancelled) => {
    setIsRecording(false);
    if (wasCancelled) {
      console.log('Recording was cancelled');
      // No hacemos nada más, ya que la grabación fue cancelada
    }
  };

  const handleSendRecording = async (audioBlob, durationSeconds) => {
    try {
      if (!user) {
        console.log('Usuario no autenticado');
        toast.error('Usuario no autenticado.');
        return;
      }

      console.log('Iniciando proceso de envío de grabación');

      // Generar un UID único para el archivo
      const audioUID = uuidv4();
      console.log('UID generado:', audioUID);

      // Subir el archivo a Firebase Storage
      console.log('Iniciando subida del archivo...');
      const snapshot = await uploadBytes(storageRef(storage, `voicenotes/${user.uid}/${audioUID}.wav`), audioBlob);
      console.log('Archivo subido exitosamente');

      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log('URL de descarga obtenida:', downloadURL);

      // Preparar los datos de la nota
      const noteData = {
        uid: user.uid,
        title: "Nueva Nota de Voz",
        transcript: "",
        summary: "",
        recordingURL: downloadURL,
        isPending2Process: true,
        recordingSizeKb: Math.round(audioBlob.size / 1024),
        recordingDuration: durationSeconds,
        deviceRecordingURL: "", // No aplica en web
        isEmailSent: false,
        isTodoCompleted: false,
        isProcessing: true,
        processingStatus: 10, // Trascribiendo
        processingProgress: 10,
        processingLastError: "",
        isPendingtoDelete: false,
        noteType: 0,
        insights: "",
        calendarTitle: "",
        calendarIsAllDay: false,
        hasLinks: false,
        extId: audioUID,
        shared: false,
        sharedViewed: false,
        isPinned: false,
        source: "webapp",
      };
      console.log('Datos de la nota preparados');

      // Llamar a la función de Firebase para procesar la nota
      console.log('Llamando a la función processNote...');
      const processNote = httpsCallable(functions, 'processNote');
      const result = await processNote({
        docId: "create_new",
        recordingURL: downloadURL,
        processTranscript: true,
        processSummary: true,
        processMetadata: true,
        processSendEmail: true,
        noteData: noteData,
      });

      console.log('Respuesta de processNote recibida:', result);

      // Modificar esta parte
      if (result.data && (result.data.result === 'Ok' || result.data.message === 'Note processed successfully')) {
        console.log('Nota procesada exitosamente');
        // Trackear la creación de la nota
        trackFirebaseNoteCreated(audioUID, durationSeconds);
        //toast.success('Nota de voz enviada correctamente.');
      } else {
        console.error('Error en el procesamiento de la nota:', result.data);
        toast.error('Error al procesar la nota de voz.');
      }
    } catch (error) {
      console.error('Error detallado al enviar la nota de voz:', error);
      toast.error('Hubo un error al enviar la nota de voz.');
    } finally {
      console.log('Proceso de envío de grabación finalizado');
      setIsRecording(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#F8FAFB] flex items-center justify-center">
        <div className="text-gray-400 text-base">Loading...</div>
      </div>
    );
  }

  return (
    <div className="app-container min-h-screen bg-[#F8FAFB]">
      {user && <Header user={user} />}
      <main className="app-main w-full px-4 sm:px-6 lg:px-8 py-6 pt-20">
        <div className="max-w-7xl mx-auto">
          <Routes>
            <Route path="/login" element={user ? <Navigate to={isFirstTimeUser ? "/profile" : "/notes"} /> : <Login />} />
            <Route
              path="/profile"
              element={user ? <UserProfile user={user} /> : <Navigate to="/login" />}
            />
            <Route
              path="/notes"
              element={user ? <Notes /> : <Navigate to="/login" />}
            />
            <Route path="/voicenotes_public_link" element={<NotePlayerPublicLink />} />
            <Route path="/finishSignIn" element={<Login />} /> {/* Opcional: Puede redirigir a otra página */}
            <Route path="/" element={user ? <Navigate to={isFirstTimeUser ? "/profile" : "/notes"} /> : <Navigate to="/login" />} />
          </Routes>
        </div>
      </main>
      <ToastContainer />
      
      {/* Botón Flotante de Grabación */}
      {user && <FloatingRecordButton onClick={handleOpenRecording} />}

      {/* Panel de Grabación */}
      {isRecording && (
        <RecordingPanel
          onClose={handleCloseRecording}
          onSend={handleSendRecording}
        />
      )}
    </div>
  );
}

export default App;