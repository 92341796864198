// srv/constants.js

export const PAGE_SIZE = 30; // Número de notas por página
export let MAX_RECORDING_DURATION = 60*5; // 5 minutes in seconds (valor por defecto)

// Added signInProviderMap
export const SIGN_IN_PROVIDER_MAP = {
  0: 'Email',
  1: 'Google',
  2: 'Apple',
};

// Added Mixpanel token
export const MIXPANEL_TOKEN = "34ab1b0082ca711910c7d2fb5eb3ed3b";