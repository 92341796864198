import React, { useState, useEffect, useRef, useCallback } from 'react';
import { XMarkIcon, TrashIcon, PauseIcon, PlayIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { MediaRecorder } from 'extendable-media-recorder';
import { userService } from '../services/UserService';
import { MAX_RECORDING_DURATION } from '../constants';

function RecordingPanel({ onClose, onSend }) {
  const [isPaused, setIsPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const chunksRef = useRef([]);
  const timerIntervalRef = useRef(null);
  const durationRef = useRef(0);
  const isCancelledRef = useRef(false);
  const wakeLockRef = useRef(null);

  // Función auxiliar para obtener la duración máxima
  const getMaxDuration = () => {
    return userService.currentUser?.maxNoteLengthSec || MAX_RECORDING_DURATION;
  };

  const startTimer = useCallback(() => {
    console.log('startTimer called');
    if (timerIntervalRef.current) clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = setInterval(() => {
      setDuration(prev => {
        const newDuration = prev + 1;
        console.log('Timer tick, new duration:', newDuration);
        durationRef.current = newDuration;

        // Usar getMaxDuration() en lugar de MAX_RECORDING_DURATION
        if (newDuration >= getMaxDuration()) {
          console.log('Duración máxima alcanzada. Deteniendo la grabación.');
          if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.pause();
            setIsPaused(true);
            stopTimer();
            alert('Se ha alcanzado la duración máxima de grabación. La grabación se ha pausado.');
          }
        }

        return newDuration;
      });
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    console.log('stopTimer called');
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  }, []);

  const handlePause = useCallback(() => {
    console.log('handlePause called');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
    }
  }, [stopTimer]);

  const handleResume = useCallback(() => {
    console.log('handleResume called');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
    }
  }, [startTimer]);

  const handleSend = useCallback(() => {
    console.log('handleSend called, current duration:', durationRef.current);
    releaseWakeLock(); // Liberar wake lock al enviar
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
  }, []);

  const handleCancel = useCallback(() => {
    console.log('handleCancel called');
    isCancelledRef.current = true;
    
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    
    chunksRef.current = [];
    stopTimer();
    setIsRecording(false);
    setIsPaused(false);
    setDuration(0);
    
    // Mover la liberación del wake lock al final
    releaseWakeLock();
    
    onClose(true);
  }, [onClose, stopTimer]);

  const calculateProgress = (currentDuration) => {
    // Usar getMaxDuration() en lugar de MAX_RECORDING_DURATION
    return Math.min((currentDuration / getMaxDuration()) * 100, 100);
  };

    /**
   * Convierte un AudioBuffer a un Blob en formato WAV.
   * @param {AudioBuffer} buffer 
   * @returns {Blob}
   */
  const audioBufferToWav = (buffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const bufferArray = new ArrayBuffer(length);
    const view = new DataView(bufferArray);

    /* RIFF identifier */
    writeString(view, 0, 'RIFF');
    /* file length */
    view.setUint32(4, 36 + buffer.length * numOfChan * 2, true);
    /* RIFF type */
    writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, 1, true);
    /* channel count */
    view.setUint16(22, numOfChan, true);
    /* sample rate */
    view.setUint32(24, 16000, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, 16000 * numOfChan * 2, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, numOfChan * 2, true);
    /* bits per sample */
    view.setUint16(34, 16, true);
    /* data chunk identifier */
    writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, buffer.length * numOfChan * 2, true);

    // Write interleaved data
    let offset = 44;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        let sample = buffer.getChannelData(channel)[i];
        // Clipping
        sample = Math.max(-1, Math.min(1, sample));
        // Convert to 16-bit PCM
        sample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
        view.setInt16(offset, sample, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: 'audio/wav' });
  };

  /**
   * Escribe una cadena de texto en el DataView.
   * @param {DataView} view 
   * @param {number} offset 
   * @param {string} string 
   */
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  /**
   * Procesa el blob de audio para reducir la frecuencia de muestreo y convertir a mono.
   * @param {Blob} blob 
   * @returns {Promise<Blob>}
   */
  const processAudioBlob = async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);

    // Crear OfflineAudioContext para re-muestrear
    const offlineContext = new OfflineAudioContext(1, decodedData.duration * 16000, 16000);

    // Crear buffer fuente
    const source = offlineContext.createBufferSource();
    source.buffer = decodedData;

    // Conectar y renderizar
    source.connect(offlineContext.destination);
    source.start(0);
    const renderedBuffer = await offlineContext.startRendering();

    // Convertir a Blob WAV
    const wavBlob = audioBufferToWav(renderedBuffer);
    return wavBlob;
  };

  const requestWakeLock = async () => {
    try {
      if ('wakeLock' in navigator && !wakeLockRef.current) {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
        console.log('Wake Lock activado');
        // Quitamos el event listener para evitar reactivaciones automáticas
      }
    } catch (err) {
      console.error('Error al solicitar Wake Lock:', err);
    }
  };

  const releaseWakeLock = async () => {
    if (wakeLockRef.current) {
      try {
        await wakeLockRef.current.release();
        wakeLockRef.current = null;
        console.log('Wake Lock liberado');
      } catch (err) {
        console.error('Error al liberar Wake Lock:', err);
        wakeLockRef.current = null;
      }
    }
  };

  useEffect(() => {
    console.log('useEffect for recorder initialization');
    const initRecorder = async () => {
      try {
        await requestWakeLock();

        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
          }
        });
        streamRef.current = stream;

        const recorder = new MediaRecorder(stream, {
          mimeType: 'audio/wav'
        });
        mediaRecorderRef.current = recorder;

        recorder.ondataavailable = (event) => {
          if (!isCancelledRef.current) {
            chunksRef.current.push(event.data);
          }
        };

        recorder.onstart = () => {
          console.log('Recorder started');
          setIsRecording(true);
          setDuration(0);
          durationRef.current = 0;
          startTimer();
        };

        recorder.onpause = () => {
          console.log('Recorder paused');
          stopTimer();
        };

        recorder.onresume = () => {
          console.log('Recorder resumed');
          startTimer();
        };

        recorder.onstop = async () => {
          console.log('Recorder stopped, final duration:', durationRef.current);
          stopTimer();
          setIsRecording(false);
          releaseWakeLock(); // Liberar wake lock cuando se detiene la grabación
          
          if (!isCancelledRef.current) {
            try {
              let audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
              console.log('Audio blob created, size:', audioBlob.size);

              // Procesar el blob para reducir la frecuencia y convertir a mono
              audioBlob = await processAudioBlob(audioBlob);
              console.log('Processed audio blob size:', audioBlob.size);

              if (durationRef.current >= 5) {
                console.log('Sending audio, duration:', durationRef.current);
                onSend(audioBlob, durationRef.current);
              } else {
                console.log('Audio too short, duration:', durationRef.current);
                alert('La grabación debe durar al menos 5 segundos para enviarla.');
              }
              chunksRef.current = [];
              onClose(false);
            } catch (error) {
              console.error('Error processing audio:', error);
              alert('Error al procesar el audio. ' + error.message);
              onClose(true);
            }
          }
        };

        recorder.start();
        console.log('Recorder initialized and started');
      } catch (error) {
        console.error('Error initializing recorder:', error);
        releaseWakeLock();
        alert('No se pudo inicializar el grabador de audio. ' + error.message);
        onClose(true);
      }
    };

    initRecorder();

    return () => {
      if (isCancelledRef.current) {
        // Si ya se canceló, el wake lock ya debería estar liberado
        return;
      }
      handleCancel();
    };
  }, [onClose, onSend, startTimer, stopTimer, handleCancel]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    // Usar getMaxDuration() en lugar de MAX_RECORDING_DURATION
    const maxDuration = getMaxDuration();
    return `${mins}:${secs}/${Math.floor(maxDuration / 60).toString().padStart(2, '0')}:00`;
  };

  console.log('Render: duration =', duration, 'isPaused =', isPaused, 'isRecording =', isRecording);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-80">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Recording note</h2>
          <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="text-center mb-4">
          <p className="text-gray-700 mb-2">{formatTime(duration)}</p>
          <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 my-4">
            <div 
              className="bg-orange-500 h-1.5 rounded-full transition-all duration-300 ease-in-out" 
              style={{ width: `${calculateProgress(duration)}%` }}
            ></div>
          </div>
        </div>
        <div className="flex justify-around">
          <button
            onClick={handleCancel}
            className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
            title="Cancel recording"
          >
            <TrashIcon className="w-6 h-6 text-red-500" />
          </button>
          {isPaused ? (
            <button
              onClick={handleResume}
              className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
              title="Resume recording"
            >
              <PlayIcon className="w-6 h-6 text-blue-500" />
            </button>
          ) : (
            <button
              onClick={handlePause}
              className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
              title="Pause recording"
            >
              <PauseIcon className="w-6 h-6 text-yellow-500" />
            </button>
          )}
          <button
            onClick={handleSend}
            disabled={duration < 5 || !isRecording}
            className={`p-2 bg-gray-200 hover:bg-gray-300 rounded-full ${(duration < 5 || !isRecording) ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Send recording"
          >
            <PaperAirplaneIcon className="w-6 h-6 text-green-500 transform rotate-45" />
          </button>
        </div>
      </div>
    </div>
  );
}

RecordingPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default RecordingPanel;
