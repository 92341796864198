// Definimos los enums y estructuras necesarias
const SignInProvider = {
  EMAIL_LINK: 0,
  GOOGLE: 1,
  APPLE: 2,

  toString: function(value) {
    switch(value) {
      case this.GOOGLE: return "Google";
      case this.APPLE: return "Apple";
      case this.EMAIL_LINK: return "Email";
      default: return "Unknown";
    }
  }
};

class FolderModel {
  constructor(id, name, parentName = null) {
    this.id = id;
    this.name = name;
    this.parentName = parentName;
  }
}

class UserContact {
  constructor(name, email, sharesCount = 0) {
    this.id = crypto.randomUUID();
    this.name = name;
    this.email = email;
    this.sharesCount = sharesCount;
  }
}

class UserService {
  constructor() {
    this._currentUser = null;
    this._listeners = new Set();
  }

  get currentUser() {
    return this._currentUser;
  }

  setUser(firebaseUser, userData) {
    this._currentUser = {
      // Datos de Firebase Auth
      email: firebaseUser?.email || null,
      uid: firebaseUser?.uid || null,
      photoURL: firebaseUser?.photoURL || null,
      displayName: firebaseUser?.displayName || null,

      // Datos adicionales de Firestore
      sendEmailAddress: userData?.sendEmailAddress || null,
      hintKeywords: userData?.hintKeywords || [],
      maxNoteLengthSec: userData?.maxNoteLengthSec || null,
      fcmTokens: userData?.fcmTokens || {},
      fcmTokenLocalDevice: userData?.fcmTokenLocalDevice || null,
      aboutMe: userData?.aboutMe || null,
      userLocale: userData?.userLocale || navigator.language,
      appInterfaceLanguageLocale: userData?.appInterfaceLanguageLocale || navigator.language,
      signInProvider: userData?.signInProvider || null,
      
      // Arrays de objetos
      folders: (userData?.folders || []).map(f => new FolderModel(f.id, f.name, f.parentName)),
      shareContacts: (userData?.shareContacts || []).map(c => new UserContact(c.name, c.email, c.sharesCount)),
      teamContacts: (userData?.teamContacts || []).map(c => new UserContact(c.name, c.email, c.sharesCount))
    };

    this._notifyListeners();
  }

  clearUser() {
    this._currentUser = null;
    this._notifyListeners();
  }

  // Método para suscribirse a cambios
  subscribe(listener) {
    this._listeners.add(listener);
    return () => this._listeners.delete(listener);
  }

  _notifyListeners() {
    this._listeners.forEach(listener => listener(this._currentUser));
  }
}

export const userService = new UserService();
export { SignInProvider, FolderModel, UserContact }; 